import DOMPurify from 'dompurify';
import { setFormatter } from 'oskari-frontend/bundles/mapping/mapmodule/plugin/getinfo/ValueFormatters';
/**
 * Joensuun Mapillary-kohteiden esittämisen GFI-vastauksen muotoiluun tehty koodi.
 * Tasolla oletetaan olevan kuvan id, joka leivotaan sisään upotettavan iframen urliin.
 *
 * - muotoilun käyttöönotto tasolle: layer.attributes.JNS_GFI_formatter = "mapillaryIframe"
 * - muotoilussa haettavan ominaisuustiedon nimi: layer.attributes.JNS_Mapillary_GFI_property = "[ominaisuustietokentän nimi]"
 * - käyttäjälle näytettävä teksti: layer.attributes.PTI_GFI_label (voi olla string tai locale object, määrittelemättömänä "Vuosi")
 *
 * Esim attributes kenttään:
 * {
    "JNS_GFI_formatter": "mapillaryIframe",
    "JNS_Mapillary_GFI_property": "identifier"
   }
 *
 * Käyttöönotto esim. applications alla index.js:ssä:
 *
 *     import { MapillaryGFIformatter } from '../../util/MapillaryGFIformatter';
 *
 * Oskari.app.startApplication() callbackissä:
 *
 *     sandbox.findRegisteredModuleInstance('MainMapModuleGetInfoPlugin').addLayerFormatter(new MapillaryGFIformatter());
 */
const mapillaryIframeFormatter = (value, params = {}) => {
    // TODO: onError=replace with nicer placeholder? Maybe when refactoring to React?
    if (!value) {
        return '';
    }
    return `<iframe width="640" height="480"
        src="https://www.mapillary.com/embed?version=1&image_key=${value}"
        frameborder="0"></iframe>`;
};
export class MapillaryGFIformatter {
    enabled (data = {}) {
        const layerAttributes = this.getLayerAttributes(data.layerId);
        return layerAttributes.JNS_GFI_formatter === 'mapillaryIframe';
    }

    format (data = {}) {
        const layerAttributes = this.getLayerAttributes(data.layerId);
        try {
            const geojson = JSON.parse(data.content);
            const imageIdProp = layerAttributes.JNS_Mapillary_GFI_property || 'identifier';
            const images = geojson.features.map(feature => feature.properties[imageIdProp]);
            return images.map(mapillaryIframeFormatter);
        } catch (err) {
            Oskari.log('MapillaryGFIformatter').warn(`Couldn't format GFI data`, data);
        }
    }

    getLayerAttributes (id) {
        const layer = Oskari.getSandbox().getService('Oskari.mapframework.service.MapLayerService').findMapLayer(id);
        if (!layer) {
            return {};
        }
        return layer.getAttributes() || {};
    }
};

/**
 * For using with WFS-layer - configure layer attributes with:

    {
        "data": {
            "filter": ["identifier"],
            "format": {
            "identifier": {
                "type": "mapillaryimg",
                "noLabel": true
            }
            }
        }
    }

This will make identifier the only property to render and the value is formatted with mapillaryIframeFormatter()
*/

setFormatter('mapillaryimg', mapillaryIframeFormatter);

// override sanitize to allow iframes... [insert scream emoji]
Oskari.util.sanitize = function (content) {
    return DOMPurify.sanitize(content, { ADD_TAGS: ['iframe'], ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling', 'target'] });
    // original impl was: return DOMPurify.sanitize(content, {ADD_ATTR: ['target']});
};
